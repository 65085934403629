footer {
    background: var(--bg);
    padding: 20px;
    text-align: center;
    border-top: var(--border);
  }
  
  footer p {
    font-size: 14px;
    color: #999;
    margin: 0;
  }
  